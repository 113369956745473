import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
    // return Cookies.get(TokenKey)
    return localStorage.getItem(TokenKey)
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, token)
    // return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey)
    // return Cookies.remove(TokenKey)
}

const PhoneKey = 'SET_PHONE'

export function getPhone() {
    return localStorage.getItem(PhoneKey)
    // return Cookies.get(PhoneKey)
}

export function setPhone(token) {
    return localStorage.setItem(PhoneKey, token)
    // return Cookies.set(PhoneKey, token)
}

export function removePhone() {
    return localStorage.removeItem(PhoneKey)
    // return Cookies.remove(PhoneKey)
}

const PageKey = 'SET_PAGE'

export function getPage() {
    return localStorage.getItem(PageKey)
    // return Cookies.get(PhoneKey)
}

export function setPage(Page) {
    return localStorage.setItem(PageKey, Page)
}

export function removePage() {
    return localStorage.removeItem(PageKey)
    // return Cookies.remove(PhoneKey)
}
