<template>
    <div :class="{'has-logo':showLogo}"
         :style="{ backgroundColor: settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground }">
        <logo v-if="showLogo" :collapse="isCollapse"/>
        <el-scrollbar :class="settings.sideTheme" wrap-class="scrollbar-wrapper">
            <el-menu
                    :default-active="activeMenu"
                    :collapse="isCollapse"
                    :background-color="settings.sideTheme === 'theme-dark' ? variables.menuBackground : variables.menuLightBackground"
                    :text-color="settings.sideTheme === 'theme-dark' ? variables.menuColor : variables.menuLightColor"
                    :unique-opened="true"
                    :active-text-color="settings.theme"
                    :collapse-transition="false"
                    mode="vertical"
            >
                <sidebar-item
                        v-for="(route, index) in permission_routes"
                        :key="route.path  + index"
                        :item="route"
                        :base-path="route.path"
                />
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import Logo from "./Logo";
    import SidebarItem from "./SidebarItem";
    import variable from "@/assets/style/variables.module.scss";

    export default {
        components: {SidebarItem, Logo},
        computed: {
            ...mapState(["settings"]),
            ...mapGetters(["permission_routes", "sidebar"]),
            activeMenu() {
                const route = this.$route;
                const {meta, path} = route;
                // if set path, the sidebar will highlight the path you set
                if (meta.activeMenu) {
                    return meta.activeMenu;
                }
                return path;
            },
            showLogo() {
                return this.$store.state.settings.sidebarLogo;
            },
            variables() {
                return variable;
            },
            isCollapse() {
                return !this.sidebar.opened;
            }
        }
    };
</script>
