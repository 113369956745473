import {Login, logout, PagePull} from '@/api/user'
import {
    getToken,
    setToken,
    removeToken,
    getPhone,
    setPhone,
    removePhone,
    getPage,
    setPage,
    removePage
} from '@/utils/auth'
import router, {resetRouter} from '@/router'

const state = {
    token: getToken(),
    phone: getPhone(),
    name: '',
    avatar: "",
    introduction: '',
    roles: [],
    pageList: JSON.parse(getPage()),
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_PHONE: (state, phone) => {
        state.phone = phone
    },
    SET_PAGELIST: (state, pageList) => {
        state.pageList = pageList
    }
}

const actions = {
    // user login
    login({commit}, userInfo) {
        const {phone, code} = userInfo
        return new Promise((resolve, reject) => {
            // try {
            //     commit('SET_TOKEN', "tiantianchuxing");
            //     setToken("tiantianchuxing");
            //     resolve()
            // } catch (error) {
            //     reject(error)
            // }
            Login({phone: phone.trim(), code: code.trim()}).then(response => {
                const {data} = response
                commit('SET_TOKEN', data.token);
                setToken(data.token);
                commit('SET_PAGELIST', data.page);
                setPage(JSON.stringify(data.page));
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user info
    getInfo({commit, state}) {
        return new Promise((resolve, reject) => {
            try {
                const data = {
                    roles: ["admin"],
                    name: "admin",
                    avatar: "",
                    introduction: "",
                };
                if (!data) {
                    reject('Verification failed, please Login again.')
                }
                const {roles, name, avatar, introduction} = data
                if (!roles || roles.length <= 0) {
                    reject('getInfo: roles must be a non-null array!')
                }
                commit('SET_ROLES', roles)
                commit('SET_NAME', name)
                commit('SET_AVATAR', avatar)
                commit('SET_INTRODUCTION', introduction)
                commit('SET_AVATAR', require("@/assets/img/1.jpeg"));
                resolve(data)
            } catch (error) {
                reject(error)
            }
            // getInfo(state.token).then(response => {
            //   const { data } = response
            //
            //   if (!data) {
            //     reject('Verification failed, please Login again.')
            //   }
            //
            // const {roles, name, avatar, introduction} = data
            //
            //   // roles must be a non-empty array
            //   if (!roles || roles.length <= 0) {
            //     reject('getInfo: roles must be a non-null array!')
            //   }
            //
            //   commit('SET_ROLES', roles)
            //   commit('SET_NAME', name)
            //   commit('SET_AVATAR', avatar)
            //   commit('SET_INTRODUCTION', introduction)
            //   resolve(data)
            // }).catch(error => {
            //   reject(error)
            // })
        })
    },

    // user logout
    LogOut({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            logout(getPhone()).then(() => {
                try {
                    commit('SET_TOKEN', '')
                    commit('SET_ROLES', [])
                    commit('SET_PAGELIST', []);
                    removeToken()
                    removePage()
                    // reset visited views and cached views
                    // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
                    resolve()
                } catch (error) {
                    reject(error)
                }

            }).catch(error => {
                reject(error)
            })
        })
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    },
    PagePull({commit}) {
        return new Promise((resolve, reject) => {
            PagePull().then(response => {
                const {data} = response;
                commit('SET_PAGELIST', data);
                // setToken(data.token);
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // dynamically modify permissions
    async changeRoles({commit, dispatch}, role) {
        const token = role + '-token'

        commit('SET_TOKEN', token)
        setToken(token)

        const {roles} = await dispatch('getInfo')

        resetRouter()

        // generate accessible routes map based on roles
        const accessRoutes = await dispatch('permission/generateRoutes', roles, {root: true})
        // dynamically add accessible routes
        router.addRoutes(accessRoutes)

        // reset visited views and cached views
        dispatch('tagsView/delAllViews', null, {root: true})
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
