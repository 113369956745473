//安装 npm install axios
import axios from "axios"
import {getToken} from "@/utils/auth";
import {MessageBox, Notification} from "element-ui";
import store from "@/store"

axios.defaults.headers["Content-type"] = "application/json;charset=uft-8"; //配置请求头信息

//创建axios实例
const service = axios.create({
    baseURL: '/', // process.env.NODE_ENV === "production" ? "https://xxx.com/api" : 地址
    // baseURL: process.env.NODE_ENV === "production" ? "https://shucangtest.tiantianchuxing.cn/" : '/api', // process.env.NODE_ENV === "production" ? "https://xxx.com/api" : 地址
    // timeout: 10000   //超时时间
});
//axios请求前
service.interceptors.request.use(config => {
        if (getToken()) {
            config.headers['user-secret'] = getToken() //添加token
        }
        return config
    },
    error => {
        Promise.reject(error)
    }
);
//请求返回
service.interceptors.response.use(response => {
        const code = response.data.code;
        if (code === 400 || code === 405) {
            MessageBox.confirm(
                '登录已过期，请重新登录！',
                "系统提示！",
                {
                    type: "warning",
                    cancelButtonClass: false,
                    confirmButtonText: "重新登陆"
                }
            ).then(() => {
                store.dispatch("user/LogOut").then(() => {
                    location.reload() //刷新页面:
                })
            })
        } else if (code !== 200) {
            Notification.error({title: response.data.message, duration: 2000}) //duration 相应时间
            return Promise.reject("error")
        } else {
            return response.data
        }
    },
    error => {
        Notification.error({title: error.message, duration: 2000});
        return Promise.reject(error)
    }
);

export default service
