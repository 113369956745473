import router, {asyncRoutes, constantRoutes} from '@/router'
import {UserModularList} from "@/api/SecondEdition/modular"
import Layout from '@/layout/index'
import {IsArray} from "@/utils/validate"

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
    if (route.meta && route.meta.roles) {
        return roles.some(role => route.meta.roles.includes(role))
    } else {
        return true
    }
}

/**
 * 通过角色过滤菜单
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
    const res = []

    routes.forEach(route => {
        const tmp = {...route}
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles)
            }
            res.push(tmp)
        }
    })

    return res
}

/**
 * 前端页面挂载实时路由
 * @param view
 * */
export const loadView = (view) => {
    if (process.env.NODE_ENV === 'development') {
        return (resolve) => require([`@/views/${view}`], resolve)
    } else {
        // 使用 import 实现生产环境的路由懒加载
        return () => import(`@/views/${view}`)
    }
}

function operData(list) {
    let arr = [];
    for (let i = list.length - 1; i >= 0; i--) {
        if (!IsArray(list[i].children)) {
            list.splice(i, 1)
        }
    }

    function Child(listOne) {
        let arrList = [];
        listOne.forEach(item => {
            arrList.push({
                path: item.folder,
                component: loadView(item.path_url),
                name: item.folder,
                meta: {title: item.name, icon: item.icon}
            })
        })
        return arrList
    }

    list.forEach(items => {
        arr.push({
            path: '/' + items.folder,
            meta: {title: items.name, icon: items.icon},
            component: Layout,
            children: Child(items.children)
        })
    })
    return arr

}

const state = {
    routes: [],
    addRoutes: []
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        state.routes = constantRoutes.concat(routes)
    }
}

const actions = {
    generateRoutes({commit}, roles) {
        return new Promise(resolve => {

            let accessedRoutes
            if (roles.includes('admin')) {
                accessedRoutes = asyncRoutes || []
            } else {
                accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
            }
            commit('SET_ROUTES', accessedRoutes);
            resolve(accessedRoutes)

            // UserModularList({user_code: 1}).then(res => {
            //     let list = operData(JSON.parse(JSON.stringify(res.data.list)));
            //     let accessedRoutes
            //     if (roles.includes('admin')) {
            //         accessedRoutes = list || []
            //     } else {
            //         accessedRoutes = filterAsyncRoutes(list, roles)
            //     }
            //     commit('SET_ROUTES', accessedRoutes);
            //     resolve(accessedRoutes)
            // }).catch(err => {
            //     console.log(err);
            // })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
