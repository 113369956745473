import request from '@/utils/request'

//获取手机验证码
export function GetDingCode(data) {
    return request({
        url: '/login/login/GetDingCode',
        method: 'post',
        data
    })
}

//登录
export function Login(data) {
    return request({
        url: '/login/login/Login',
        method: 'post',
        data
    })
}

//退出登录
export function logout(phone) {
    return request({
        url: 'index/Index/LogOut',
        method: 'get',
        params: {phone}
    })
}
/**页码*/
export function PagePull() {
    return request({
        url: '/gatherdata/gather/PagePull',
        method: 'get',
    })
}/**页码*/
export function CleanCache() {
    return request({
        url: '/index/Index/CleanCache',
        method: 'get',
    })
}
