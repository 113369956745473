/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
    let timeout, args, context, timestamp, result

    const later = function () {
        // 据上一次触发时间间隔
        const last = +new Date() - timestamp

        // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last)
        } else {
            timeout = null
            // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
            if (!immediate) {
                result = func.apply(context, args)
                if (!timeout) context = args = null
            }
        }
    }
}

/**
 * 判断是否为数组
 * */
export function IsArray(data) {
    if (Array.isArray(data)) {
        if (data.length > 0) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

/**对象转数组*/
export function SetObj(obj) {  //{key:"数组",name:"duixiang"}  [[{key:1}],]
    let arr = [];
    for (let i in obj) {
        arr.push({label: obj[i], value: Number(i)});
    }
    return arr;
}

// 表单重置
export function resetForm(refName) {
    if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
    }
}

/**判断数组中某属性 只能为字母下划线
 * @param list 数组
 * @param key  数组对象中的属性值
 *@return  符合要求返回对象 不符合要求则返回undefined
 * */

export function ArrayRules(list, key) {
    let reg = /^[a-zA-Z_]+$/;  //符合标准的是true  不符合的是false

    //判断下滑线是否存在于开始或者结尾
    function underline(str) {
        if (str.indexOf("_") === -1) return true;
        return str.indexOf("_") !== -1 && str.indexOf("_") !== 0 && str.indexOf("_") !== str.length - 1
    }

    return list.find(item => !reg.test(item[key]) || !underline(item[key]))
}

/***
 * 判断字符串是否为空 为null 为undefined
 */

export function IsString(str) {
    return str !== "" && str != null && str !== undefined
}


/**
 * 表单时间格式转化*/
export function DateRangeOperation(form, dateRange, start, end) {

    if (IsArray(dateRange)) {
        form[start] = dateRange[0];
        form[end] = dateRange[1]
    } else {
        form[start] = "";
        form[end] = ""
    }
    return form
}


