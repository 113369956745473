import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// import {FirstEdition} from "./modules/FirstEdition" //第一版路由
// import {DataResources, Programme, DAModel, CustomDimension} from "./modules/SecondEdition" //第二版路由
import {SecondRouter} from "./modules/SecondEdition" //第二版路由

/* Layout */
import Layout from '@/layout/index'
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
    {
        path: '/',
        component: Layout,
        redirect: '/',
        // hidden: true,
        children: [
            {
                path: '/',
                component: () => import('@/views/Index/index'),
                // component: () => import('@/views/HomeView'),
                name: 'Index',
                meta: {title: '首页', icon: 'dashboard', affix: true}
            }
        ]
    },
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect/index')
            }
        ]
    },
    {
        path: '/login',
        component: () => import('@/views/login/index'),
        hidden: true
    },

    {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error-page/401'),
        hidden: true
    },
    {
        path: '/largePanel',
        component: () => import('@/views/SecondEdition/Dashboard/panel/largePanel'),
        hidden: true
    },  {
        path: '/largeIndex',
        component: () => import('@/views/Index/largeIndex'),
        hidden: true
    },
    /**[数据资源] 不想在侧边栏显示的路由，但是需要侧边栏的样式 路由集合*/
    {
        hidden: true,
        component: Layout,
        path: '/DataResources',
        children: [
            {
                meta: {title: '规则管理(明细)', icon: 'el-icon-s-tools', activeMenu: '/RulesOper/list', breadcrumb: false},
                name: 'RulesOper',
                path: 'RulesOper/:extract_id(\\d+)',
                component: () => import('@/views/FirstEdition/DatabaseLink/RulesOper'),
            }, {
                meta: {
                    title: '计算管理(明细)',
                    icon: 'el-icon-s-tools',
                    activeMenu: '/CountManagement/list',
                    breadcrumb: false
                },
                name: 'CountManagement',
                path: 'CountManagement/:extract_id(\\d+)',
                component: () => import('@/views/FirstEdition/DatabaseLink/CountManagement'),
            }, {
                meta: {title: '维度/指标管理', icon: 'el-icon-s-tools'},
                name: 'Dimension',
                path: 'Dimension',
                component: () => import('@/views/SecondEdition/Dimension/index'),
            },
            {
                meta: {title: '采集管理', icon: 'el-icon-s-tools',},
                name: 'Gather',
                path: 'Gather',
                component: () => import('@/views/SecondEdition/gather/index'),
            }, {
                meta: {title: '度量运算管理', icon: 'el-icon-s-tools',},
                name: 'Measurement',
                path: 'Measurement',
                component: () => import('@/views/SecondEdition/Measurement/index'),
            },
            {
                meta: {title: '目标表维度关联', icon: 'el-icon-s-tools',},
                name: 'Association',
                path: 'Association',
                component: () => import('@/views/SecondEdition/Association/index'),
            }, {
                meta: {title: '事实表详情', icon: 'el-icon-s-tools',},
                name: 'FactTable',
                path: 'FactTable',
                component: () => import('@/views/SecondEdition/FactTable/index'),
            },
            {
                path: 'TestIndex',
                component: () => import('@/views/SecondEdition/Dashboard/panel/index'),
                name: 'TestIndex',
                meta: {title: '仪表盘', icon: 'el-icon-s-tools'}
                // meta: {title: '测试banner', icon: 'el-icon-s-tools', roles: ['editor']}
            },
            {
                path: 'TestSecond',
                component: () => import('@/views/SecondEdition/Dashboard/operationView/index'),
                name: 'TestSecond',
                meta: {title: '仪表盘配置', icon: 'el-icon-s-tools'}
                // meta: {title: '测试banner', icon: 'el-icon-s-tools', roles: ['editor']}
            },
            {
                path: 'dataView',
                component: () => import('@/views/SecondEdition/potionSetting/dataView'),
                name: 'dataView',
                meta: {title: '数据查看', icon: 'el-icon-s-tools'}
                // meta: {title: '测试banner', icon: 'el-icon-s-tools', roles: ['editor']}
            },
        ]
    }

]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [

    // {
    //     path: '/DataResources',
    //     component: Layout,
    //     redirect: 'noRedirect',
    //     alwaysShow: true, //一直显示根路由
    //     name: "数据资源",
    //     meta: {
    //         title: '数据资源',
    //         // icon: 'el-icon-s-tools'
    //         icon: 'el-icon-monitor'
    //     },
    //     children: [
    //         // {
    //         //     path: 'DatabaseLink',
    //         //     component: () => import('@/views/FirstEdition/DatabaseLink/index'),
    //         //     name: 'DatabaseLink',
    //         //     meta: {title: '数据连接', icon: 'el-icon-s-tools'}
    //         // },
    //         {
    //             path: 'DatabaseTable',
    //             component: () => import('@/views/FirstEdition/DatabaseLink/DatabaseTable'),
    //             name: 'DatabaseTable',
    //             meta: {title: '数据库表管理', icon: 'el-icon-s-tools'}
    //         }, {
    //             path: 'HomeWork',
    //             component: () => import('@/views/FirstEdition/DatabaseLink/HomeWork'),
    //             name: 'HomeWork',
    //             meta: {title: '作业管理', icon: 'el-icon-s-tools'}
    //         },
    //         {
    //             meta: {title: '规则管理', icon: 'el-icon-s-tools'},
    //             name: 'RulesOper',
    //             path: 'RulesOper/list',
    //             component: () => import('@/views/FirstEdition/DatabaseLink/RulesOper'),
    //         },
    //         {
    //             meta: {title: '计算管理', icon: 'el-icon-s-tools'},
    //             name: 'CountManagement',
    //             path: 'CountManagement/list',
    //             component: () => import('@/views/FirstEdition/DatabaseLink/CountManagement'),
    //         },
    //
    //     ]
    // },
    // FirstEdition,
    // DataResources,
    // Programme,
    // DAModel,
    // CustomDimension,
    ...SecondRouter
    // {
    //     path: '/test',
    //     component: Layout,
    //     redirect: '/test/testIndex',
    //     name: "test",
    //     meta: {
    //         title: '测试menu',
    //         icon: 'el-icon-monitor',
    //         roles: ['admin', "editor"]
    //     },
    //     children: [
    //         {
    //             path: 'TestIndex',
    //             component: () => import('@/views/FirstEdition/test/index'),
    //             name: 'TestIndex',
    //             meta: {title: '测试banner', icon: 'el-icon-s-tools', roles: ['editor']}
    //         },
    //         {
    //             path: 'TestSecond',
    //             component: () => import('@/views/FirstEdition/test/second'),
    //             name: 'TestSecond',
    //             meta: {title: '测试权限', icon: 'el-icon-s-tools', roles: ['admin']}
    //         }
    //     ]
    // },
]

let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(err => err)
}

export default new Router({
    // mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})
